import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
import { method } from "lodash";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    pullData({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://cms.stanislaus.arsconnect.com/user-sessions?kioskId=sZ2ch5Y3rofVnxXB3PTEwPLSEoz2&_limit=-1",
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.request(config).then((response) => {
        console.log("Response: ", response.data);
        let usedSession = response.data.filter(
          (doc) => JSON.parse(doc.userSession).length > 2
        );
        console.log("Used Session: ", usedSession);
        console.log(
          "Spanish : ",
          usedSession.filter((item) =>
            item.userSession.includes("Language changed to Spanish")
          ).length
        );
        console.log(
          "Mandarin : ",
          usedSession.filter((item) =>
            item.userSession.includes("Language changed to Mandarin")
          ).length
        );
        console.log(
          "Punjabi : ",
          usedSession.filter((item) =>
            item.userSession.includes("Language changed to Punjabi")
          ).length
        );
        console.log(
          "Assyrian : ",
          usedSession.filter((item) =>
            item.userSession.includes("Language changed to Assyrian")
          ).length
        );
      });
    },
    pullSessionData({ commit }) {
      console.log("Coming in here");
      const startDate = new Date("2024-08-01");
      const endDate = new Date("2024-09-31");
      fb.userSessionCollection //userSessionCollection
        .where("kioskID", "==", "sZ2ch5Y3rofVnxXB3PTEwPLSEoz2")
        .where("userSessionStartTime", ">=", startDate)
        .where("userSessionStartTime", "<", endDate)
        .get()
        .then((session) => {
          console.log("At session pull: ", session);
          session.docs.forEach((doc) => {
            let data = {
              kioskId: doc.data().kioskID,
              userSession: doc.data().userSessionLogs,
              sessionStartTime: doc.data().userSessionStartTime.toDate(),
              sessionEndTime: doc.data().userSessionEndTime.toDate(),
            };
            console.log("Session: ", data);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://cms.stanislaus.arsconnect.com/user-sessions",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios
              .request(config)
              .then((response) => {
                //console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
    },

    pullQnaData({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://cms.stanislaus.arsconnect.com/qnas?_limit=-1",
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .request(config)
        .then((response) => {
          
          response.data.forEach((doc) => {
            console.log(doc);
            let config2 = {
              method: "put",
              maxBodyLength: Infinity,
              url: "https://cms.stanislaus.arsconnect.com/qnas/" + doc.id,
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                dateTimeStamp: doc.timeStamp,
              },
            };
            axios.request(config2).then((response) => {
              console.log("Date Updated");
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
